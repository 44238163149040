<template>
  <div class="record">
    <div class="wrap">
      <div class="rr-title animate__animated animate__bounceInUp">
        <img src="../../public/images/asset/back.png" @click="$router.go(-1)" />
        {{ $t("record.desc") }}
      </div>
      <div class="rr-h5-time-out animate__animated animate__bounceInUp">
        <div class="rr-h5-time">
          <img src="../../public/images/asset/time-icon.png" />
          <div class="item" @click="startShow = true">
            <input
              type="text"
              v-model="startTime"
              :placeholder="$t('record.desc1')"
              disabled
            />
          </div>
          <span>{{ $t("record.desc2") }}</span>
          <div class="item" @click="endShow = true">
            <input
              type="text"
              v-model="endTime"
              :placeholder="$t('record.desc3')"
              disabled
            />
          </div>
        </div>
      </div>
      <div class="rr-tabbar flex animate__animated animate__bounceInUp">
        <div class="rr-select">
          <div class="rr-type">{{ $t("record.desc4") }}</div>
          <p>
            <el-select
              v-model="typeName"
              :placeholder="$t('planet.desc1')"
              @change="selectType"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item, i) in typeList"
                :key="i"
              ></el-option>
            </el-select>
          </p>
        </div>
        <div class="rr-select rr-select-time">
          <div class="rr-type">{{ $t("record.desc5") }}</div>
          <p>
            <el-date-picker
              @change="dateSelect"
              v-model="value1"
              type="daterange"
              :range-separator="$t('record.desc2')"
              :start-placeholder="$t('record.desc1')"
              :end-placeholder="$t('record.desc3')"
            >
            </el-date-picker>
          </p>
        </div>
        <div class="rr-select">
          <div class="rr-type">{{ $t("record.desc6") }}</div>
          <p>
            <el-select
              v-model="coinName"
              :placeholder="$t('planet.desc1')"
              @change="selectCoin"
            >
              <el-option
                :label="item.coinName"
                :value="item.coinId"
                v-for="(item, i) in coinList"
                :key="i"
              ></el-option>
            </el-select>
          </p>
        </div>
      </div>
      <div
        class="rr-wrap animate__animated animate__bounceInUp"
        v-if="recordList && recordList.length > 0"
      >
        <div class="rr-wrap-pc">
          <el-table :data="recordList" style="width: 100%">
            <el-table-column
              prop="date"
              :label="$t('record.desc7')"
              width="260"
            />
            <el-table-column
              prop="type"
              :label="$t('record.desc8')"
              width="130"
            />
            <el-table-column :label="$t('record.desc9')" width="210">
              <template #default="scope">
                <div class="flex tem-item">
                  {{ dealAddress(scope.row.address) }}
                  <img
                    class="copy"
                    @click="copy($event, scope.row.address)"
                    src="../../public/images/asset/copy.png"
                    alt=""
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('record.desc6')" width="140">
              <template #default="scope">
                <div class="flex">
                  <img :src="scope.row.iconIcon" alt="" />
                  {{ scope.row.coinName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('record.desc10')" width="140">
              <template #default="scope">
                <div class="flex tem-item">
                  {{ scope.row.amount }}
                  {{ scope.row.coinName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="dataSn"
              :label="$t('record.desc11')"
              width="280"
            />
            <el-table-column prop="status" :label="$t('record.desc12')" />
          </el-table>
        </div>
        <div class="rr-wrap-h5">
          <div
            class="item"
            v-for="(item, i) in recordList"
            :key="i"
            @click="goDetail(item)"
          >
            <img
              class="detail-go"
              src="../../public/images/asset/detail-go.png"
              alt=""
            />
            <div class="item-l">
              <img
                v-if="item.typeCode == 2"
                src="../../public/images/asset/icon-h-2.png"
                alt=""
              />
              <img
                v-else-if="item.typeCode == 4"
                src="../../public/images/asset/icon-h-3.png"
                alt=""
              />
              <img v-else src="../../public/images/asset/icon-h-1.png" alt="" />
              <div class="content">
                <span>{{ item.type }}</span>
                <span>{{ item.date }}</span>
              </div>
            </div>
            <div class="item-r">
              <span>{{ item.amount }}</span>
              <span>{{ item.status }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="rr-wrap animate__animated animate__bounceInUp" v-else>
        <div class="no-data">
          <img src="../../public/images/no-data.png" alt="" />
          <span>{{ $t("record.desc13") }}</span>
        </div>
      </div>
      <div
        class="rr-page animate__animated animate__bounceInUp"
        v-if="recordList && recordList.length > 0"
      >
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <van-popup
      v-model:show="startShow"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-datetime-picker
        v-model="startCurrentTime"
        type="date"
        @confirm="confirmStartFn()"
        @cancel="startShow = false"
      />
    </van-popup>
    <van-popup
      v-model:show="endShow"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-datetime-picker
        v-model="endCurrentTime"
        type="date"
        @confirm="confirmEndFn()"
        @cancel="endShow = false"
      />
    </van-popup>
  </div>
</template>

<script>
import Clipboard from "@/utils/libs/clipboard";
import { mapState } from "vuex";
export default {
  data() {
    return {
      type: -1,
      typeName: "",
      isCoinListLoading: false,
      typeList: [
        {
          name: this.$t("record.desc14"),
          id: -1,
        },
        {
          name: this.$t("asset.desc2"),
          id: 1,
        },
        {
          name: this.$t("asset.desc1"),
          id: 2,
        },
        {
          name: this.$t("asset.desc3"),
          id: 4,
        },
      ],
      coinId: "",
      coinName: "",
      coinList: [],
      isRecordListLoading: false,
      recordList: [],
      page: 1,
      pageSize: 10,
      total: 0,
      startTime: "",
      endTime: "",
      value1: "",
      startShow: false,
      endShow: false,
      startCurrentTime: new Date(),
      endCurrentTime: new Date(),
    };
  },
  computed: {
    ...mapState(["address"]),
  },
  mounted() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    this.selectType(this.type);
    this.handleCoinList();
  },
  methods: {
    handleCoinList() {
      if (this.isCoinListLoading) return;
      this.isCoinListLoading = true;
      this.$post(this.URL.assets.info, {
        accountType: 1,
      }).then((res) => {
        if (res.code == 0) {
          this.isCoinListLoading = false;
          let arr = res.data.list;
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].hasOpen) {
              this.coinList.push(arr[i]);
            }
          }
          this.coinId = "";
          this.coinList.unshift({
            coinId: "",
            coinName: this.$t("record.desc14"),
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleRecordList() {
      if (this.isRecordListLoading) return;
      this.isRecordListLoading = true;
      let canType = [];
      if (this.type > 0) {
        canType.push(this.type + "");
      } else {
        canType = "";
      }
      this.$post(this.URL.assets.record, {
        coinId: this.coinId,
        types: canType,
        startTime: this.startTime,
        endTime: this.endTime,
        page: this.page,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.code == 0) {
          this.isRecordListLoading = false;
          this.recordList = res.data.list;
          this.recordList.forEach((item) => (item.address = this.address));
          this.total = res.data.page.count;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleCurrentChange(num) {
      this.page = num;
      this.handleRecordList();
    },
    dateSelect(arr) {
      this.startTime = this.newDate(arr[0]);
      this.endTime = this.newDate(arr[1]);
      this.page = 1;
      this.handleRecordList();
    },
    confirmStartFn() {
      this.startTime = this.newDate(this.startCurrentTime);
      this.startShow = false;
      if (this.endTime) {
        this.page = 1;
        this.handleRecordList();
      }
    },
    confirmEndFn() {
      this.endTime = this.newDate(this.endCurrentTime);
      this.endShow = false;
      if (this.startTime) {
        this.page = 1;
        this.handleRecordList();
      }
    },
    selectType(id) {
      // 根据类型查询
      this.type = id;
      for (let i = 0; i < this.typeList.length; i++) {
        const element = this.typeList[i];
        if (element.id == id) {
          this.typeName = element.name;
          break;
        }
      }
      this.page = 1;
      this.handleRecordList();
    },
    newDate(time) {
      // 根据时间查询
      var date = new Date(time);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    selectCoin(id) {
      // 根据币种查询
      this.coinId = id;
      this.page = 1;
      this.handleRecordList();
    },
    dealAddress(str) {
      if (str && str.length > 10) {
        return (
          str.substring(0, 6) +
          "...." +
          str.substring(str.length - 4, str.length)
        );
      } else {
        return str;
      }
    },
    copy(e, text) {
      // @click="copy($event,googleRandom.secretKey)"
      let that = this;
      Clipboard(e, text).then((res) => {
        that.$message.success(this.$t("planet.desc61"));
      });
    },
    goDetail(item) {
      localStorage.setItem("detailItem", JSON.stringify(item));
      this.$router.push({
        path: "record-detail",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.record {
  background: #000;
  padding: 116px 0 20px;
  width: 100%;
  min-width: 1240px;
  // min-height: 100vh;

  .wrap {
    margin: 0 auto;
    width: 1240px;
  }

  .rr-title {
    font-size: 24px;
    color: #ffe27f;
    margin-bottom: 40px;
    animation-delay: 0.1s;
    img {
      display: none;
    }
  }
  .rr-h5-time-out {
    display: none;
    animation-delay: 0.2s;
  }

  .rr-tabbar {
    margin-bottom: 14px;
    animation-delay: 0.2s;

    .rr-select {
      flex: 0 0 160px;
      margin-right: 24px;

      div {
        margin-bottom: 10px;
        font-size: 13px;
        color: #a8a8a8;
      }

      ::v-deep .el-select {
        width: 100%;
        height: 40px;
        border: 0;

        .el-input__wrapper {
          height: 40px;
          background-color: #1e1e1e;
          box-shadow: none !important;
          border: 1px solid #ffe279 !important;
          backdrop-filter: blur(7px) !important;
          box-sizing: border-box;

          .el-input__inner {
            font-size: 16px;
            color: #ffffff;
            font-family: "BaiJamjuree-Medium";
          }

          .el-input__suffix-inner {
            i {
              position: relative;

              &::after {
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                width: 10px;
                height: 6px;
                background: url("../../public/images/downicon.png") center
                  no-repeat;
                background-size: 100% 100%;
                transform: translateY(-50%);
              }

              svg {
                display: none;
              }
            }
          }
        }
      }

      ::v-deep .el-input__wrapper {
        height: 40px;
        background-color: #1e1e1e;
        box-shadow: none !important;
        border: 1px solid #ffe279 !important;
        backdrop-filter: blur(7px) !important;

        .el-range-input {
          font-size: 16px;
          color: #ffffff;
          font-family: "BaiJamjuree-Medium";
          line-height: 40px;
        }
      }
    }
  }

  .rr-wrap {
    animation-delay: 0.3s;
    .rr-wrap-pc {
      display: block;
    }
    .rr-wrap-h5 {
      display: none;
    }
    .no-data {
      width: 100%;
      padding: 130px 0;

      img {
        display: block;
        width: 120px;
        height: 120px;
        margin: 0 auto 16px;
      }

      span {
        display: block;
        margin: 0 auto;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #a1a1a1;
      }
    }

    .tem-item {
      display: flex;
      align-items: center;

      img {
        width: 12px;
        height: 12px;
        margin: 0 4px;
      }

      .copy {
        width: 12px;
        height: 12px;
        margin: 0 4px;
        cursor: pointer;
      }
    }
    :deep(.el-table) {
      --el-table-tr-bg-color: transparent;
      --el-table-bg-color: transparent;
    }

    :deep(.el-table--border .el-table__inner-wrapper::after),
    :deep(.el-table--border::after),
    :deep(.el-table--border::before),
    :deep(.el-table__inner-wrapper::before) {
      border-bottom: none;
      background: transparent;
    }

    :deep(.el-table .cell) {
      // padding: 0 24px;
      padding: 0;
      font-family: "Bai Jamjuree";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 13px;
      text-transform: capitalize;
      color: rgba(255, 255, 255, 1);
    }

    :deep(.el-table__row) {
      background: #000;
      cursor: pointer;
    }

    :deep(
        .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell
      ) {
      background: #000;
    }

    :deep(.el-table .el-table__cell) {
      // background: #000;
      height: 64px;
      border-bottom: 1px solid rgba(255, 226, 127, 0.3);

      &:first-child {
        padding-left: 24px;
      }

      &:last-child {
        padding-right: 24px;
      }
    }

    :deep(.el-table th.el-table__cell) {
      background: rgba(255, 226, 127, 0.3);
      height: 64px;
      border-bottom: none;
    }
    :deep(.el-table__header) {
      border-radius: 8px;
      overflow: hidden;
    }

    :deep(.el-table th.el-table__cell .cell) {
      font-family: "PingFang SC";
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .rr-page {
    padding: 40px 0;
    animation-delay: 0.4s;

    ::v-deep .el-pagination {
      button {
        background-color: rgba(255, 255, 255, 0);
        color: #666666;
        font-size: 14px;
      }

      .el-pager {
        li {
          background: rgba(255, 255, 255, 0);
          font-size: 14px;
          color: #666666;
        }

        .is-active {
          background: #ffe279 !important;
          border-radius: 4px !important;
          color: #000000 !important;
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .record {
    padding: 68px 0 72px;
    min-width: 0;
    .wrap {
      width: 100%;
    }
    .rr-title {
      height: 71px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Bai Jamjuree";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      text-transform: capitalize;
      color: #ffe27f;
      position: relative;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      margin-bottom: 16px;
      img {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
        display: block;
        width: 17px;
        height: auto;
        cursor: pointer;
      }
    }
    .rr-h5-time {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 11px;
      height: 48px;
      padding: 0 44px 0 10px;
      background: rgba(255, 226, 127, 0.3);
      border-radius: 8px;
      &-out {
        display: block;
        padding: 0 16px;
      }
      img {
        width: 16px;
        height: 16px;
        margin-right: 18px;
      }
      span {
        display: block;
        font-family: "Bai Jamjuree";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        text-transform: capitalize;
        color: #ffffff;
        padding: 0 18px;
      }
      input {
        width: 100%;
        font-family: "Bai Jamjuree";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        text-transform: capitalize;
        color: #ffffff;
        &::placeholder {
          font-family: "Bai Jamjuree";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
          text-transform: capitalize;
          color: rgba(255, 255, 255, 0.3);
        }
      }
    }
    .rr-tabbar {
      display: flex;
      justify-content: space-between;
      padding: 0 16px;
      margin-bottom: 0;
      .rr-select {
        flex: 0 0 166px;
        margin-right: 0;
        margin-bottom: 8px;
        ::v-deep .el-select {
          width: 100%;
          height: 48px;
          border: 0;

          .el-input__wrapper {
            height: 48px;
            background: rgba(255, 226, 127, 0.3) !important;
            border-radius: 8px !important;
            border: none !important;
            box-shadow: none !important;
            box-sizing: border-box;
          }
        }
        .rr-type {
          display: none;
        }
      }
      .rr-select-time {
        display: none;
      }
    }
    .rr-wrap {
      .no-data {
        width: 100%;
        padding: 60px 0;

        img {
          display: block;
          width: 100px;
          height: 100px;
          margin: 0 auto;
        }

        span {
          display: block;
          margin: 0 auto;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 10px;
          text-align: center;
          color: #a1a1a1;
        }
      }
      .rr-wrap-pc {
        display: none;
      }
      .rr-wrap-h5 {
        display: block;
        padding: 0 16px;
        .item {
          padding: 16px 13px 16px 0;
          border-bottom: 1px solid rgba(255, 226, 127, 0.3);
          position: relative;
          display: flex;
          justify-content: space-between;
          .detail-go {
            position: absolute;
            top: 50%;
            right: 0;
            width: 6px;
            height: auto;
          }
          .item-l {
            display: flex;
            img {
              width: 36px;
              height: 36px;
              margin-right: 10px;
            }
            .content {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              span {
                display: block;
                font-family: "Bai Jamjuree";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 16px;
                text-transform: capitalize;
                color: #ffffff;
                &:last-child {
                  font-family: "Bai Jamjuree";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 12px;
                  text-transform: capitalize;
                  color: #bbbbbb;
                }
              }
            }
          }
          .item-r {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            span {
              display: block;
              font-family: "Bai Jamjuree";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 16px;
              text-transform: capitalize;
              color: #ffffff;
              &:last-child {
                font-family: "Bai Jamjuree";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                text-transform: capitalize;
                color: #bbbbbb;
              }
            }
          }
        }
      }
    }
    .rr-page {
      position: fixed;
      bottom: 0;
      left: 16px;
      right: 16px;
      padding: 20px 0;
      background: #000;
    }
    ::v-deep .van-popup {
      background: #000;
      .van-picker {
        background: linear-gradient(
          180deg,
          rgba(255, 226, 127, 0.2) -10.71%,
          rgba(110, 85, 38, 0.2) 84.58%
        );
        .van-picker__toolbar {
          background: rgba(255, 226, 127, 0.3);
          .van-picker__cancel {
            color: #fff;
          }
          .van-picker__confirm {
            color: #ffe27f;
          }
        }
        .van-picker__columns {
          .van-picker__mask {
            display: none;
          }
        }
        .van-picker-column__item {
          font-family: "Bai Jamjuree";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 16px;
          text-align: center;
          color: #ffffff;
        }
        .van-hairline--top-bottom:after,
        .van-hairline-unset--top-bottom:after {
          border-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}
</style>
